<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="isValid" :no-confirm="tooManyForms">
    <template #title>
      {{ $t('waste_identification.export_modal.title') }}
    </template>
    <template #default v-if="tooManyForms">
      {{ $t('waste_identification.export_modal.too_many_forms_selected', {amount: ids.length}) }}
    </template>
    <template #default v-else>
      <div class="pb-2">
        {{ $t('waste_identification.export_modal.export_forms', {amount: amount ?? ids.length}) }}
      </div>
      <label>{{ $t('waste_identification.export_modal.export_type') }}</label>
      <v-select data-cy="export_type" :options="types ?? ExportType.getAll()" v-model="exportType">
        <template #search="{attributes, events}">
          <input
              class="vs__search"
              :required="!exportType"
              v-bind="attributes"
              v-on="events"
          />
        </template>
        <template #no-options>
          {{ $t('general.no_options') }}
        </template>
      </v-select>
      <template v-if="exportType === 'PDF'">
        <br/>
        <label>{{ $t('user.labels.language') }}:</label>
        <language-selector v-model="language" :required="true"/>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import LanguageSelector from '@/components/LanguageSelector'
import ExportType from '@/types/exportType'
import {useExportStore} from '@/stores/modals/ExportStore'
import {storeToRefs} from 'pinia'

export default {
  name:       'FormExportModal',
  computed:   {
    ExportType() {
      return ExportType
    },
  },
  components: {LanguageSelector, UiModal},
  props:      {
    callback: {
      type: Function,
    },
    types:    {
      type:    Array,
      default: null,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const exportStore = useExportStore()

    const isValid = () => {
      return validAmount() && validLanguage() && validType()
    }

    const validAmount = () => {
      return exportStore.ids.length > 0
    }

    const validLanguage = () => {
      return !!exportStore.language
    }

    const validType = () => {
      return !!exportStore.exportType
    }

    const cb = async () => {
      if (props.callback) {
        await props.callback(exportStore.language, exportStore.exportType)
      } else {
        await exportStore.exportFunc()
      }
    }

    return {
      modal,
      ...storeToRefs(exportStore),
      isValid,
      cb,
    }
  },
}
</script>
