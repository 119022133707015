<template>
  <div id="DigidFormView" class="container-fluid">
    <!-- Basic Information -->
    <div class="row">
      <ui-view-card id="BasicInfoCard" type="col-lg-12" :title="$t('form.title.basic_information')">
        <div class="row">
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.unique_identifier') }}:</strong> {{ form.id }}
            <br />
            <strong>{{ $t('form.labels.uuid') }}:</strong> {{ form.uuid }}
            <br />
            <strong>{{ $t('form.labels.transport_instructions') }}:</strong> {{ form.transportInstructions }}
          </div>
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.status') }}:</strong> {{ form.status }}
            <br/>
            <strong>{{ $t('form.labels.transport_date') }}:</strong> {{ DateFormatter.formatDate(form.transportDate) }}
            <br/>
            <strong v-if="form.reasonDiscontinuation">{{ $t('form.labels.reason_for_discontinuation') }}:</strong>
            {{ form.reasonDiscontinuation }}
          </div>
        </div>
      </ui-view-card>

      <!-- Producer Information -->
      <ui-view-card id="ProducerInfoCard" type="col-lg-6" :title="$t('form.title.waste_producer')">
        <div class="row">
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.wasteProducer.name }}
            <div class="d-flex">
              <div>
                <strong>{{ $t('address.address') }}:</strong>&nbsp;
              </div>
              <div>
                {{ form.wasteProducer.address.street }}
                {{ form.wasteProducer.address.houseNumber }}{{
                  form.wasteProducer.address.mailboxNumber
                  ? ', ' + form.wasteProducer.address.mailboxNumber
                  : ''
                }}
                <br />
                {{ form.wasteProducer.address.postalCode }} {{ form.wasteProducer.address.municipality }},
                {{ form.wasteProducer.address.country }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <span v-if="form.wasteProducer.identificationNumber.vatNumber">
              <strong>VAT:</strong> {{ form.wasteProducer.identificationNumber.vatNumber }}
            </span><br />
            <span v-if="form.wasteProducer.identificationNumber.enterpriseNumber">
              <strong>Enterprise:</strong> {{ form.wasteProducer.identificationNumber.enterpriseNumber }}
            </span>
          </div>
          <div class="col-lg-12">
            <strong>{{ $t('form.labels.additional_information') }}:</strong>
            <ul>
              <li v-for="(value, key) in form.wasteProducer.additionalInformation" :key="key">
                <strong>{{ key }}:</strong> {{ value }}
              </li>
            </ul>
          </div>
          <hr />
          <h6 class="card-subtitle text-muted">
            {{$t('form.sending_address')}}
          </h6>
          <div class="d-flex">
            <div>
              <strong>{{ $t('address.address') }}:</strong>&nbsp;
            </div>
            <div>
              {{ form.wasteProducer.pickupLocation.address.street }}
              {{ form.wasteProducer.pickupLocation.address.houseNumber }}{{
                form.wasteProducer.pickupLocation.address.mailboxNumber
                ? ', ' + form.wasteProducer.pickupLocation.address.mailboxNumber
                : ''
              }}
              <br />
              {{ form.wasteProducer.pickupLocation.address.postalCode }} {{ form.wasteProducer.pickupLocation.address.municipality }},
              {{ form.wasteProducer.pickupLocation.address.country }}
            </div>
          </div>
        </div>
      </ui-view-card>

      <!-- Treatment Facility -->
      <ui-view-card id="TreatmentFacilityCard" type="col-lg-6" :title="$t('form.title.treatment_facility')">
        <div class="row">
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.treatmentFacility.name }}
            <div class="d-flex">
              <div>
                <strong>{{ $t('address.address') }}:</strong>&nbsp;
              </div>
              <div>
                {{ form.treatmentFacility.address.street }}
                {{ form.treatmentFacility.address.houseNumber }}{{
                  form.treatmentFacility.address.mailboxNumber
                  ? ', ' + form.treatmentFacility.address.mailboxNumber
                  : ''
                }}
                <br/>
                {{ form.treatmentFacility.address.postalCode }}
                {{ form.treatmentFacility.address.municipality }},
                {{ form.treatmentFacility.address.country }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <span v-if="form.treatmentFacility.identificationNumber.vatNumber">
                <strong>VAT:</strong> {{ form.treatmentFacility.identificationNumber.vatNumber }}
              </span><br/>
              <span v-if="form.treatmentFacility.identificationNumber.enterpriseNumber">
                <strong>Enterprise:</strong> {{ form.treatmentFacility.identificationNumber.enterpriseNumber }}
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <strong>{{ $t('form.labels.additional_information') }}:</strong>
            <ul>
              <li v-for="(value, key) in form.treatmentFacility.additionalInformation" :key="key">
                <strong>{{ key }}:</strong> {{ value }}
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Collector Trader Broker -->
      <ui-view-card id="CollectorTraderBrokerCard" type="col-lg-6" :title="$t('form.title.collector_trader_broker')">
        <div class="row">
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.collectorTraderBroker.name }}
            <div class="d-flex">
              <div>
                <strong>{{ $t('address.address') }}:</strong>&nbsp;
              </div>
              <div>
                {{ form.collectorTraderBroker.address.street }}
                {{ form.collectorTraderBroker.address.houseNumber }}{{
                  form.collectorTraderBroker.address.mailboxNumber
                  ? ', ' + form.collectorTraderBroker.address.mailboxNumber
                  : ''
                }}
                <br />
                {{ form.collectorTraderBroker.address.postalCode }}
                {{ form.collectorTraderBroker.address.municipality }},
                {{ form.collectorTraderBroker.address.country }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <span v-if="form.collectorTraderBroker.identificationNumber.vatNumber">
                <strong>VAT:</strong> {{ form.collectorTraderBroker.identificationNumber.vatNumber }}
              </span><br />
              <span v-if="form.collectorTraderBroker.identificationNumber.enterpriseNumber">
                <strong>Enterprise:</strong> {{ form.collectorTraderBroker.identificationNumber.enterpriseNumber }}
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <strong>{{ $t('form.labels.additional_information') }}:</strong>
            <ul>
              <li v-for="(value, key) in form.collectorTraderBroker.additionalInformation" :key="key">
                <strong>{{ key }}:</strong> {{ value }}
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Carrier Information -->
      <ui-view-card
        id="CarrierInfoCard"
        type="col-lg-6"
        :title="`${$t('form.title.carriers')} (${index + 1})`"
        v-for="(carrier, index) in form.carriers"
        :key="index"
      >
        <div class="row">
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ carrier.name }}
            <div class="d-flex">
              <div>
                <strong>{{ $t('address.address') }}:</strong>&nbsp;
              </div>
              <div>
                {{ carrier.address.street }}
                {{ carrier.address.houseNumber }}{{
                  carrier.address.mailboxNumber
                  ? ', ' + carrier.address.mailboxNumber
                  : ''
                }}
                <br />
                {{ carrier.address.postalCode }}
                {{ carrier.address.municipality }},
                {{ carrier.address.country }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <span v-if="carrier.identificationNumber.vatNumber">
                <strong>VAT:</strong> {{ carrier.identificationNumber.vatNumber }}
              </span><br />
              <span v-if="carrier.identificationNumber.enterpriseNumber">
                <strong>Enterprise:</strong> {{ carrier.identificationNumber.enterpriseNumber }}
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <strong>{{ $t('form.labels.additional_information') }}:</strong>
            <ul>
              <li v-for="(value, key) in carrier.additionalInformation" :key="key">
                <strong>{{ key }}:</strong> {{ value }}
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Waste Details -->
      <ui-view-card id="WasteDetailsCard" type="col-lg-12" :title="$t('form.title.waste_details')">
        <div v-for="(wasteItem, index) in form.waste" :key="index" class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">{{ $t('form.labels.waste_item') }} {{ index + 1 }}</h5>
            <div class="row">
              <div class="col-lg-6">
                <strong>{{ $t('form.labels.weight') }}:</strong>
                {{ wasteItem.weight.quantity }} {{ wasteItem.weight.unit }}
                <br />
                <strong>{{ $t('form.labels.eural_code') }}:</strong> {{ wasteItem.euralCode }}
                <br />
                <strong>{{ $t('form.labels.description') }}:</strong> {{ wasteItem.description }}
                <br />
                <strong>{{ $t('form.labels.treatment_type') }}:</strong> {{ wasteItem.treatmentType }}
              </div>
              <div class="col-lg-6">
                <strong>{{ $t('form.labels.treatment_codes') }}:</strong>
                {{ wasteItem.treatmentCode.join(', ') }}
                <br />
                <strong>{{ $t('form.labels.packaging_count') }}:</strong>
                {{ wasteItem.packagingCount }}
                <br />
                <strong>{{ $t('form.labels.packaging_material') }}:</strong>
                {{ wasteItem.packagingMaterial }}
                <br />
                <strong>{{ $t('form.labels.physical_properties') }}:</strong>
                {{ wasteItem.physicalProperties }}
                <br />
                <strong>{{ $t('form.labels.chemical_composition') }}:</strong>
                {{ wasteItem.chemicalComposition }}
              </div>
            </div>
          </div>
        </div>
      </ui-view-card>

      <!-- Signatures -->
      <ui-view-card id="SignaturesCard" type="col-lg-12" :title="$t('form.title.signatures')">
        <div class="row">
          <!-- Signature Responsible -->
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.signatureResponsible.name }}
            <br/>
            <strong>{{ $t('form.labels.date') }}:</strong> {{ form.signatureResponsible.date }}
            <br/>
            <strong>{{ $t('form.labels.signature_type') }}:</strong> {{ form.signatureResponsible.signatureType }}
            <br/>
            <strong>{{ $t('form.labels.audit_trail') }}:</strong>
            <a :href="form.signatureResponsible.linkAuditTrail" target="_blank">{{ $t('general.view_audit_trail') }}</a>
          </div>

          <!-- Signature Receiver -->
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.signatureReceiver.name }}
            <br/>
            <strong>{{ $t('form.labels.date') }}:</strong> {{ form.signatureReceiver.date }}
            <br/>
            <strong>{{ $t('form.labels.signature_type') }}:</strong> {{ form.signatureReceiver.signatureType }}
            <br/>
            <strong>{{ $t('form.labels.audit_trail') }}:</strong>
            <a :href="form.signatureReceiver.linkAuditTrail" target="_blank">{{ $t('general.view_audit_trail') }}</a>
          </div>
        </div>
      </ui-view-card>
    </div>
  </div>
</template>

<script>
import UiViewCard from '@/components/ui-view-card.vue'
import {DateFormatter} from '@/services/dateFormatter'

export default {
  name: "ExternalDigidFormView",
  computed: {
    DateFormatter() {
      return DateFormatter
    }
  },
  components: {UiViewCard},
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}

</script>