<template>
  <layout>
    <template #header>
      <ui-header :title="$t('transport_document_templates.waste_identification.edit.title', {name: template.name})"
                 :back-route="`/transport-document-templates/view/${template.id}`">
        <template #actions>
          <button type="button" class="btn btn-outline-secondary" @click="validate()"><i
              class="bi-check"/>&nbsp;{{ $t('general.check_template') }}
          </button>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()"
                  form="waste-identification-templates-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <div class="container-fluid">
        <form id="waste-identification-templates-form" class="row g-2">
          <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <ui-view-card-field :label="$t('form.labels.name')" v-model="templateRef.name" :required="true"
                                    :maxlength="100"/>
                <ui-view-card-field :label="$t('form.labels.status')" :model-value="$t('form.state.'+templateRef.state)"
                                    :readonly="true"/>
              </div>
              <div class="col-lg-6">
                <ui-view-card-field :label="$t('form.labels.notes')">
                  <textarea class="form-control" v-model="templateRef.notes" rows="4" style="width: 100%"
                            maxlength="255"/>
                </ui-view-card-field>
              </div>
            </div>
          </ui-view-card>
          <ui-view-card
              :title="$t('transport_document_templates.waste_identification.edit.producers')"
          >
            <template #actions>
              <button type="button" class="btn btn-sm btn-outline-primary" @click="addProducer()"><i class="bi-plus"/>&nbsp;{{
                  $t('transport_document_templates.waste_identification.edit.add_producer')
                }}
              </button>
            </template>
            <table class="table table-bordered table-striped" style="overflow-wrap: anywhere;">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.name') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.address') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.sending_address') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(producer, index) in templateRef.producers" :key="producer.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="WasteIdentificationTemplateModel.validProducer(producer)" class="color-dot-green"/>
                  <span v-else class="color-dot-red"/>
                </td>
                <td class="ellipsis">{{ producer.name }}</td>
                <td>
                  <ui-view-card-address v-model="producer.address" :compact-address="true"/>
                </td>
                <td>
                  <ui-view-card-address v-model="producer.shipmentAddress" :compact-address="true"/>
                </td>
                <td style="width: 102px">
                  <div class="btn btn-outline-secondary" @click="editProducer(index)">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteProducer(index)">
                    <i class="bi-trash"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
              :title="$t('transport_document_templates.waste_identification.edit.processors')"
          >
            <template #actions>
              <button type="button" class="btn btn-sm btn-outline-primary" @click="addProcessor()"><i class="bi-plus"/>&nbsp;{{
                  $t('transport_document_templates.waste_identification.edit.add_processor')
                }}
              </button>
            </template>
            <table class="table table-bordered table-striped" style="overflow-wrap: anywhere;">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.name') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.address') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.receiving_address') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(processor, index) in templateRef.processors" :key="processor.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="WasteIdentificationTemplateModel.validProcessor(processor)" class="color-dot-green"/>
                  <span v-else class="color-dot-red"/>
                </td>
                <td class="ellipsis">{{ processor.name }}</td>
                <td>
                  <ui-view-card-address v-model="processor.address" :compact-address="true"/>
                </td>
                <td>
                  <ui-view-card-address v-model="processor.shipmentAddress" :compact-address="true"/>
                </td>
                <td style="width: 102px">
                  <div class="btn btn-outline-secondary" @click="editProcessor(index)">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteProcessor(index)">
                    <i class="bi-trash"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
              :title="$t('transport_document_templates.waste_identification.edit.organiser')"
          >
            <template #actions>
              <button v-if="!templateRef.organiser" type="button" class="btn btn-sm btn-outline-primary"
                      @click="addOrganiser()"><i class="bi-plus"/>&nbsp;{{
                  $t('transport_document_templates.waste_identification.edit.add_organiser')
                }}
              </button>
            </template>
            <table class="table table-bordered table-striped" style="overflow-wrap: anywhere;">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.name') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.address') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="templateRef.organiser">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="WasteIdentificationTemplateModel.validOrganiser(templateRef.organiser)"
                        class="color-dot-green"/>
                  <span v-else class="color-dot-red"/>
                </td>
                <td class="ellipsis">{{ templateRef.organiser?.name }}</td>
                <td>
                  <ui-view-card-address v-model="templateRef.organiser.address" :compact-address="true"/>
                </td>
                <td style="width: 102px">
                  <div class="btn btn-outline-secondary" @click="editOrganiser()">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteOrganiser()">
                    <i class="bi-trash"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
              :title="$t('transport_document_templates.waste_identification.edit.transporters')"
          >
            <template #actions>
              <button type="button" class="btn btn-sm btn-outline-primary" @click="addTransporter()"><i
                  class="bi-plus"/>&nbsp;{{
                  $t('transport_document_templates.waste_identification.edit.add_transporter')
                }}
              </button>
            </template>
            <table class="table table-bordered table-striped" style="overflow-wrap: anywhere;">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.name') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.address') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(transporter, index) in templateRef.transporters" :key="transporter.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="WasteIdentificationTemplateModel.validTransporter(transporter)" class="color-dot-green"/>
                  <span v-else class="color-dot-red"/>
                </td>
                <td class="ellipsis">{{ transporter.name }}</td>
                <td>
                  <ui-view-card-address v-model="transporter.address" :compact-address="true"/>
                </td>
                <td style="width: 102px">
                  <div class="btn btn-outline-secondary" @click="editTransporter(index)">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteTransporter(index)">
                    <i class="bi-trash"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
              :title="$t('transport_document_templates.waste_identification.edit.waste_identification')"
          >
            <template #actions>
              <button type="button" class="btn btn-sm btn-outline-primary" @click="addWaste()"><i class="bi-plus"/>&nbsp;{{
                  $t('transport_document_templates.waste_identification.edit.add_waste')
                }}
              </button>
            </template>
            <table class="table table-bordered table-striped" style="overflow-wrap: anywhere;">
              <thead>
              <tr>
                <th></th>
                <th>{{ $t('waste_identification_template.form.description') }}</th>
                <th>{{ $t('waste_identification_template.form.amount') }}</th>
                <th>{{ $t('waste_identification_template.form.EURAL-code') }}</th>
                <th>{{ $t('transport_document_templates.waste_identification.edit.processors') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(waste, index) in templateRef.wasteIdentificationTemplateProcessors" :key="waste.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span
                      v-if="WasteIdentificationTemplateModel.validWasteIdentificationTemplateProcessors(waste, templateRef.processors)"
                      class="color-dot-green"/>
                  <span v-else class="color-dot-red"/>
                </td>
                <td>{{ waste.wasteIdentification.description }}</td>
                <td>{{ waste.wasteIdentification.amount }} {{ $t('form.table.tonne') }}</td>
                <td>{{
                    waste.wasteIdentification.euralCode
                    ? waste.wasteIdentification.euralCode.substring(6).replaceAll('_', ' ')
                    : ''
                  }}
                </td>
                <td class="ellipsis">{{ getProcessorsForWaste(waste) }}</td>
                <td style="width: 102px">
                  <div class="btn btn-outline-secondary" @click="editWaste(index)">
                    <i class="bi-pencil"/>
                  </div>
                  <div class="btn btn-danger" @click="deleteWaste(index)">
                    <i class="bi-trash"/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
        </form>
      </div>
    </ui-content>
  </layout>
  <edit-organisation-modal v-if="selectedOrganisation" ref="editOrganisationModal" :organisation="selectedOrganisation"
                           :organisation-type="selectedOrganisationType" :callback="saveOrganisation"
                           :key="selectedOrganisationType+':'+selectedOrganisation.id"/>
  <edit-waste-modal v-if="selectedWaste" ref="editWasteModal" :waste="selectedWaste"
                    :processors="templateRef.processors" :callback="saveWaste"
                    :key="templateRef.processors.map((p) => { return p.id+','+p.name }).join(',')"/>
  <form-warning-modal ref="warningModal" :callback="performSaveWaste" :warning-message="warningMessage"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {useModelWrapper} from '@/util/modelWrapper'
import OrganisationModel from '@/models/OrganisationModel'
import UiViewCardField from '@/components/ui-view-card-field'
import backend from '@/util/backend'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import {ref} from 'vue'
import ContactOrganisationType from '@/types/contactOrganisationType'
import TransportDocumentTemplateModel from '@/models/TransportDocumentTemplateModel'
import EditOrganisationModal from '@/views/transport-document-templates/waste-identification/Edit-organisation-modal'
import EditWasteModal from '@/views/transport-document-templates/waste-identification/Edit-waste-modal'
import WasteIdentificationTemplateModel from '@/models/templates/WasteIdentificationTemplateModel'
import TransportDocumentTemplateState from '@/types/transportDocumentTemplateState'
import FormWarningModal from '@/views/forms/shared/Warning-Modal.vue'

export default {
  name:       'WasteIdentificationTemplatesEdit',
  computed:   {
    WasteIdentificationTemplateModel() {
      return WasteIdentificationTemplateModel
    },
  },
  components: {
    FormWarningModal,
    EditWasteModal,
    EditOrganisationModal,
    UiViewCardField,
    UiViewCardAddress,
    UiViewCard,
    UiBreadcrumbs,
    UiContent,
    UiHeader,
    Layout,
  },
  props:      {
    template: {
      type:     Object,
      required: true,
    },
  },
  emits:      ['reload'],
  setup:      (props, {emit}) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION_TEMPLATES, BREADCRUMBS.EDIT_TEMPLATE]
    const templateRef = useModelWrapper(props, emit, 'template')

    templateRef.value.wasteIdentificationTemplateProcessors.forEach((waste) => {
      if (!waste.wasteIdentification) {
        waste.wasteIdentification = WasteIdentificationTemplateModel.empty
      }
    })

    const saving   = ref(false)
    const router   = useRouter()
    const notifier = Notifier()
    const save     = async (redirect = true) => {
      saving.value = true
      if (templateRef.value.state !== TransportDocumentTemplateState.DRAFT) {
        await TransportDocumentTemplateModel.setDraft(templateRef.value)
        delete templateRef.value.organiserSignature
      }
      const result = await backend.put(`api/transportDocumentTemplates/${templateRef.value.id}`, templateRef.value)
      if (result.status === 200) {
        if (redirect) {
          await router.push(`/transport-document-templates/view/${templateRef.value.id}`)
        } else {
          notifier.success('toast.successfully_saved')
          emit('reload')
        }
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    const editOrganisationModal     = ref('editOrganisationModal')
    const selectedOrganisation      = ref(null)
    const selectedOrganisationIndex = ref(null)
    const selectedOrganisationType  = ref(null)
    const saveOrganisation          = (organisation) => {
      switch (selectedOrganisationType.value) {
        case ContactOrganisationType.PRODUCER: {
          templateRef.value.producers[selectedOrganisationIndex.value] = organisation
          break
        }
        case ContactOrganisationType.PROCESSOR: {
          templateRef.value.processors[selectedOrganisationIndex.value] = organisation
          break
        }
        case ContactOrganisationType.ORGANISER: {
          templateRef.value.organiser = organisation
          break
        }
        case ContactOrganisationType.TRANSPORTER: {
          templateRef.value.transporters[selectedOrganisationIndex.value] = organisation
          break
        }
      }
      save(false)
      editOrganisationModal.value.modal.close()
    }

    const addProducer = () => {
      templateRef.value.producers.push(JSON.parse(JSON.stringify(OrganisationModel.empty)))
      editProducer(templateRef.value.producers.length - 1)
    }

    const deleteProducer = (index) => {
      templateRef.value.producers.splice(index, 1)
    }

    const editProducer = (index) => {
      selectedOrganisation.value      = templateRef.value.producers[index]
      selectedOrganisationIndex.value = index
      selectedOrganisationType.value  = ContactOrganisationType.PRODUCER
      setTimeout(() => {
        editOrganisationModal.value.modal.open()
      }, 1)
    }

    const addProcessor = () => {
      templateRef.value.processors.push(JSON.parse(JSON.stringify(OrganisationModel.empty)))
      editProcessor(templateRef.value.processors.length - 1)
    }

    const deleteProcessor = (index) => {
      const id = templateRef.value.processors[index].id
      templateRef.value.wasteIdentificationTemplateProcessors.forEach((waste) => {
        const idx = waste.allowedProcessors.indexOf(id)
        if (idx > -1) {
          waste.allowedProcessors.splice(idx, 1)
        }
      })
      templateRef.value.processors.splice(index, 1)
    }

    const editProcessor = (index) => {
      selectedOrganisation.value      = templateRef.value.processors[index]
      selectedOrganisationIndex.value = index
      selectedOrganisationType.value  = ContactOrganisationType.PROCESSOR
      setTimeout(() => {
        editOrganisationModal.value.modal.open()
      }, 1)
    }

    const addOrganiser = () => {
      templateRef.value.organiser = JSON.parse(JSON.stringify(OrganisationModel.empty))
      editOrganiser()
    }

    const deleteOrganiser = () => {
      templateRef.value.organiser = null
    }

    const editOrganiser = () => {
      selectedOrganisation.value     = templateRef.value.organiser
      selectedOrganisationType.value = ContactOrganisationType.ORGANISER
      setTimeout(() => {
        editOrganisationModal.value.modal.open()
      }, 1)
    }

    const addTransporter = () => {
      templateRef.value.transporters.push(JSON.parse(JSON.stringify(OrganisationModel.empty)))
      editTransporter(templateRef.value.transporters.length - 1)
    }

    const deleteTransporter = (index) => {
      templateRef.value.transporters.splice(index, 1)
    }

    const editTransporter = (index) => {
      selectedOrganisation.value      = templateRef.value.transporters[index]
      selectedOrganisationIndex.value = index
      selectedOrganisationType.value  = ContactOrganisationType.TRANSPORTER
      setTimeout(() => {
        editOrganisationModal.value.modal.open()
      }, 1)
    }

    const editWasteModal     = ref('editWasteModal')
    const selectedWaste      = ref(null)
    const selectedWasteIndex = ref(null)
    let wasteToSave          = null
    const saveWaste          = (waste) => {
      wasteToSave = waste
      if (waste.wasteIdentification.amount > 50) {
        showWarningModal()
      } else {
        performSaveWaste()
      }
    }

    const warningMessage   = ref('')
    const warningModal     = ref('warningModal')
    const showWarningModal = async () => {
      warningMessage.value = 'warning.heavy_load'
      warningModal.value.modal.open()
    }
    const performSaveWaste = async () => {
      templateRef.value.wasteIdentificationTemplateProcessors[selectedWasteIndex.value] = wasteToSave
      await save(false)
      editWasteModal.value.modal.close()
      warningModal.value.modal.close()
    }

    const addWaste = () => {
      templateRef.value.wasteIdentificationTemplateProcessors.push({
        'wasteIdentification': JSON.parse(JSON.stringify(WasteIdentificationTemplateModel.empty)),
        'allowedProcessors':   [],
      })
      editWaste(templateRef.value.wasteIdentificationTemplateProcessors.length - 1)
    }

    const deleteWaste = (index) => {
      templateRef.value.wasteIdentificationTemplateProcessors.splice(index, 1)
    }

    const editWaste = (index) => {
      selectedWaste.value      = templateRef.value.wasteIdentificationTemplateProcessors[index]
      selectedWasteIndex.value = index
      setTimeout(() => {
        editWasteModal.value.modal.open()
      }, 1)
    }

    const getProcessorsForWaste = (waste) => {
      const processorsMap = {}
      templateRef.value.processors.forEach((proc) => {
        processorsMap[proc.id] = proc.name
      })
      let names = []
      waste?.allowedProcessors.forEach((proc) => {
        names.push(processorsMap[proc])
      })
      return names.join(', ')
    }

    const validate = () => {
      const err = TransportDocumentTemplateModel.canSignWasteIdentification(props.template)
      if (err) {
        notifier.error(err)
        return false
      } else {
        notifier.success('toast.template_valid')
      }
    }

    return {
      breadcrumbs,
      templateRef,
      validate,
      save,
      saving,
      editOrganisationModal,
      selectedOrganisation,
      selectedOrganisationType,
      saveOrganisation,
      addProducer,
      deleteProducer,
      editProducer,
      addProcessor,
      deleteProcessor,
      editProcessor,
      addOrganiser,
      deleteOrganiser,
      editOrganiser,
      addTransporter,
      deleteTransporter,
      editTransporter,
      addWaste,
      deleteWaste,
      editWaste,
      editWasteModal,
      selectedWaste,
      saveWaste,
      getProcessorsForWaste,
      warningMessage,
      warningModal,
      performSaveWaste,
    }
  },
}
</script>