<template>
  <div id="DigidFormEdit" class="container-fluid">
    <!-- Basic Information -->
    <div class="row">
      <ui-view-card id="BasicInfoCard" type="col-lg-12" :title="$t('form.title.basic_information')">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('form.labels.unique_identifier')" :model-value="formRef.id" readonly/>
            <ui-view-card-field :label="$t('form.labels.uuid')" :model-value="formRef.uuid" readonly/>
            <ui-view-card-field :label="$t('form.labels.transport_instructions')"
                                v-model="formRef.transportInstructions"/>

          </div>
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('form.labels.status')" :model-value="formRef.status" readonly/>
            <ui-view-card-field :label="$t('form.labels.transport_date')">
              <datepicker data-cy="wi-transport-date" v-model="formRef.transportDate" :enableTimePicker="false"
                          :autoApply="true" :minDate="new Date()" format="dd/MM/yyyy" :required="true"/>
            </ui-view-card-field>
            <ui-view-card-field
              v-if="formRef.reasonDiscontinuation"
              :label="$t('form.labels.reason_for_discontinuation')"
              :model-value="formRef.reasonDiscontinuation"
              readonly
            />
          </div>
        </div>
      </ui-view-card>

      <!-- Producer Information -->
      <ui-view-card id="ProducerInfoCard" type="col-lg-6" :title="$t('form.title.waste_producer')">
        <div class="row">
          <ui-view-card-field :label="$t('form.labels.name')" v-model="formRef.wasteProducer.name"/>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.street')" v-model="formRef.wasteProducer.address.street"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.number')" v-model="formRef.wasteProducer.address.houseNumber"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.mailbox')"
                                  v-model="formRef.wasteProducer.address.mailboxNumber"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.zip')" v-model="formRef.wasteProducer.address.postalCode"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.city')" v-model="formRef.wasteProducer.address.municipality"/>
            </div>
          </div>
          <div>
            <ui-view-card-field :label="$t('address.country')" v-model="formRef.wasteProducer.address.country"/>
          </div>
          <ui-view-card-field class="col-lg-6" :label="'VAT'"
                              v-model="formRef.wasteProducer.identificationNumber.vatNumber"/>
          <ui-view-card-field class="col-lg-6" :label="'Enterprise'"
                              v-model="formRef.wasteProducer.identificationNumber.enterpriseNumber"/>
          <div class="col-lg-12">
            {{ $t('form.labels.additional_information') }}:
            <ul>
              <li class="col-lg-6" v-for="(value, key) in formRef.wasteProducer.additionalInformation" :key="key">
                <ui-view-card-field :label="key" :model-value="value" readonly/>
              </li>
            </ul>
          </div>
          <hr/>
          <h6 class="card-subtitle text-muted">
            {{ $t('form.sending_address') }}
          </h6>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.street')"
                                  v-model="formRef.wasteProducer.pickupLocation.address.street"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.number')"
                                  v-model="formRef.wasteProducer.pickupLocation.address.houseNumber"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.mailbox')"
                                  v-model="formRef.wasteProducer.pickupLocation.address.mailboxNumber"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.zip')"
                                  v-model="formRef.wasteProducer.pickupLocation.address.postalCode"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.city')"
                                  v-model="formRef.wasteProducer.pickupLocation.address.municipality"/>
            </div>
          </div>
          <div>
            <ui-view-card-field :label="$t('address.country')"
                                v-model="formRef.wasteProducer.pickupLocation.address.country"/>
          </div>
        </div>
      </ui-view-card>

      <!-- Treatment Facility -->
      <ui-view-card id="TreatmentFacilityCard" type="col-lg-6" :title="$t('form.title.treatment_facility')">
        <div class="row">
          <ui-view-card-field :label="$t('form.labels.name')" v-model="formRef.treatmentFacility.name"/>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.street')" v-model="formRef.treatmentFacility.address.street"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.number')"
                                  v-model="formRef.treatmentFacility.address.houseNumber"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.mailbox')"
                                  v-model="formRef.treatmentFacility.address.mailboxNumber"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.zip')" v-model="formRef.treatmentFacility.address.postalCode"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.city')" v-model="formRef.treatmentFacility.address.municipality"/>
            </div>
          </div>
          <div>
            <ui-view-card-field :label="$t('address.country')" v-model="formRef.treatmentFacility.address.country"/>
          </div>
          <ui-view-card-field class="col-lg-6" :label="'VAT'"
                              v-model="formRef.treatmentFacility.identificationNumber.vatNumber"/>
          <ui-view-card-field class="col-lg-6" :label="'Enterprise'"
                              v-model="formRef.treatmentFacility.identificationNumber.enterpriseNumber"/>
          <div class="col-lg-12">
            {{ $t('form.labels.additional_information') }}:
            <ul>
              <li class="col-lg-6" v-for="(value, key) in formRef.treatmentFacility.additionalInformation" :key="key">
                <ui-view-card-field :label="key" :model-value="value" readonly/>
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Collector Trader Broker -->
      <ui-view-card id="CollectorTraderBrokerCard" type="col-lg-6" :title="$t('form.title.collector_trader_broker')">
        <div class="row">
          <ui-view-card-field :label="$t('form.labels.name')" v-model="formRef.collectorTraderBroker.name"/>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.street')" v-model="formRef.collectorTraderBroker.address.street"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.number')"
                                  v-model="formRef.collectorTraderBroker.address.houseNumber"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.mailbox')"
                                  v-model="formRef.collectorTraderBroker.address.mailboxNumber"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.zip')"
                                  v-model="formRef.collectorTraderBroker.address.postalCode"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.city')"
                                  v-model="formRef.collectorTraderBroker.address.municipality"/>
            </div>
          </div>
          <div>
            <ui-view-card-field :label="$t('address.country')" v-model="formRef.collectorTraderBroker.address.country"/>
          </div>
          <ui-view-card-field class="col-lg-6" :label="'VAT'"
                              v-model="formRef.collectorTraderBroker.identificationNumber.vatNumber"/>
          <ui-view-card-field class="col-lg-6" :label="'Enterprise'"
                              v-model="formRef.collectorTraderBroker.identificationNumber.enterpriseNumber"/>
          <div class="col-lg-12">
            {{ $t('form.labels.additional_information') }}:
            <ul>
              <li class="col-lg-6" v-for="(value, key) in formRef.collectorTraderBroker.additionalInformation"
                  :key="key">
                <ui-view-card-field :label="key" :model-value="value" readonly/>
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Carrier Information -->


      <ui-view-card
        id="CarrierInfoCard"
        type="col-lg-6"
        :title="`${$t('form.title.carriers')} (${index + 1})`"
        v-for="(carrier, index) in form.carriers"
        :key="index"
      >
        <div class="row">
          <ui-view-card-field :label="$t('form.labels.name')" v-model="carrier.name"/>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.street')" v-model="carrier.address.street"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.number')" v-model="carrier.address.houseNumber"/>
            </div>
            <div class="col-lg-3">
              <ui-view-card-field :label="$t('address.mailbox')" v-model="carrier.address.mailboxNumber"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.zip')" v-model="carrier.address.postalCode"/>
            </div>
            <div class="col-lg-6">
              <ui-view-card-field :label="$t('address.city')" v-model="carrier.address.municipality"/>
            </div>
          </div>
          <div>
            <ui-view-card-field :label="$t('address.country')" v-model="carrier.address.country"/>
          </div>
          <ui-view-card-field class="col-lg-6" :label="'VAT'" v-model="carrier.identificationNumber.vatNumber"/>
          <ui-view-card-field class="col-lg-6" :label="'Enterprise'"
                              v-model="carrier.identificationNumber.enterpriseNumber"/>
          <div class="col-lg-12">
            {{ $t('form.labels.additional_information') }}:
            <ul>
              <li class="col-lg-6" v-for="(value, key) in carrier.additionalInformation" :key="key">
                <ui-view-card-field :label="key" :model-value="value" readonly/>
              </li>
            </ul>
          </div>
        </div>
      </ui-view-card>

      <!-- Waste Details-->
      <ui-view-card id="WasteDetailsCard" type="col-lg-12" :title="$t('form.title.waste_details')">
        <div v-for="(wasteItem, index) in form.waste" :key="index" class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">{{ $t('form.labels.waste_item') }} {{ index + 1 }}</h5>
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <ui-view-card-field :label="$t('form.labels.quantity')" v-model="wasteItem.weight.quantity"/>
                  </div>
                  <div class="col-lg-6">
                    <ui-view-card-field :label="$t('form.labels.unit')" v-model="wasteItem.weight.unit"/>
                  </div>
                </div>
                <ui-view-card-field :label="$t('form.labels.eural_code')" v-model="wasteItem.euralCode"/>
                <ui-view-card-field :label="$t('form.labels.description')" v-model="wasteItem.description"/>
                <ui-view-card-field :label="$t('form.labels.treatment_type')" v-model="wasteItem.treatmentType"/>
              </div>
              <div class="col-lg-6">
                <ui-view-card-field v-for="(_, index) in wasteItem.treatmentCode" :key="index" :label="$t('form.labels.treatment_code')">
                  <div class="d-flex">
                    <input v-model="wasteItem.treatmentCode[index]" type="text" class="form-control"/>
                    <button v-if="index===0" class="btn btn-sm btn-primary" @click="wasteItem.treatmentCode.push('')">
                      <i class="bi-plus"/>
                    </button>
                    <button v-else class="btn btn-sm btn-danger" @click="wasteItem.treatmentCode.splice(index, 1)">
                      <i class="bi-trash"/>
                    </button>
                  </div>
                </ui-view-card-field>
                <ui-view-card-field :label="$t('form.labels.packaging_count')" v-model="wasteItem.packagingCount"/>
                <ui-view-card-field :label="$t('form.labels.packaging_material')"
                                    v-model="wasteItem.packagingMaterial"/>
                <ui-view-card-field :label="$t('form.labels.physical_properties')"
                                    v-model="wasteItem.physicalProperties"/>
                <ui-view-card-field :label="$t('form.labels.chemical_composition')"
                                    v-model="wasteItem.chemicalComposition"/>
              </div>
            </div>
          </div>
        </div>
      </ui-view-card>

      <!-- Signatures -->
      <ui-view-card id="SignaturesCard" type="col-lg-12" :title="$t('form.title.signatures')">
        <div class="row">
          <!-- Signature Responsible -->
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.signatureResponsible.name }}
            <br/>
            <strong>{{ $t('form.labels.date') }}:</strong> {{ form.signatureResponsible.date }}
            <br/>
            <strong>{{ $t('form.labels.signature_type') }}:</strong> {{ form.signatureResponsible.signatureType }}
            <br/>
            <strong>{{ $t('form.labels.audit_trail') }}:</strong>
            <a :href="form.signatureResponsible.linkAuditTrail" target="_blank">{{ $t('general.view_audit_trail') }}</a>
          </div>

          <!-- Signature Receiver -->
          <div class="col-lg-6">
            <strong>{{ $t('form.labels.name') }}:</strong> {{ form.signatureReceiver.name }}
            <br/>
            <strong>{{ $t('form.labels.date') }}:</strong> {{ form.signatureReceiver.date }}
            <br/>
            <strong>{{ $t('form.labels.signature_type') }}:</strong> {{ form.signatureReceiver.signatureType }}
            <br/>
            <strong>{{ $t('form.labels.audit_trail') }}:</strong>
            <a :href="form.signatureReceiver.linkAuditTrail" target="_blank">{{ $t('general.view_audit_trail') }}</a>
          </div>
        </div>
      </ui-view-card>
    </div>
  </div>
</template>

<script>
import UiViewCard from '@/components/ui-view-card.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import Datepicker from '@vuepic/vue-datepicker'
import {ref} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name:       'ExternalDigidFormEdit',
  methods:    {useModelWrapper},
  components: {
    Datepicker,
    UiViewCardField,
    UiViewCard,
  },
  props:      {
    form: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    let formRef = ref(props.form)

    return {
      formRef,
    }
  },
}

</script>