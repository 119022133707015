<template>
  <layout>
    <template #header>
      <ui-header :title="$t('digid.edit.title', {id})" :back-route="`/external/waste-identification/view/${id}`">
        <template #actions>
          <button data-cy="digid-save" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="formStore.save()"
                  form="digid-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <external-digid-form-edit v-if="!loading" :form="form" />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout.vue'
import UiHeader from '@/components/ui-header.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiContent from '@/components/ui-content.vue'
import BREADCRUMBS from '@/util/breadcrumbs'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'
import {watch} from 'vue'
import {storeToRefs} from 'pinia'
import ExternalDigidFormEdit from '@/partials/external/digid/FormEdit.vue'

export default {
  name:       'ExternalWasteIdentificationEdit',
  components: {
    ExternalDigidFormEdit,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props: {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.EXTERNAL, BREADCRUMBS.EXTERNAL_WASTE_IDENTIFICATION, BREADCRUMBS.EDIT]

    const formStore = useFormStore()
    formStore.init(DocumentType.DIGID)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    return {
      breadcrumbs,
      formStore,
      ...storeToRefs(formStore),
    }
  }
}
</script>
