import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import backend from '@/util/backend'
import {DateFormatter} from '@/services/dateFormatter'
import GrondbankModel from '@/models/GrondbankModel'
import DocumentType from '@/types/documentType'
import FormState from '@/types/formState'
import Notifier from '@/util/notifier'
import {useRouter} from 'vue-router'
import Annex7Model from '@/models/Annex7Model'
import {useWarningStore} from '@/stores/modals/WarningStore'
import ViewStep from '@/types/viewStep'
import WasteIdentificationModel from '@/models/forms/WasteIdentificationModel'

export const useFormStore = defineStore('form', () => {
  const notifier     = Notifier()
  const router       = useRouter()
  const loading      = ref(true)
  let formType       = null
  const form         = ref({})
  const warningStore = useWarningStore()
  const viewStep     = ref(ViewStep.VIEWING)

  const init = (type) => {
    formType       = type
    viewStep.value = ViewStep.VIEWING
  }

  const loadForm = async (id) => {
    loading.value = true
    await reloadForm(id)
    loading.value = false
  }

  const resetForm = async () => {
    form.value = {}
  }

  const reloadForm = async (id = null) => {
    try {
      let url = `api/documents/${id ?? form.value.id}`
      if (formType === DocumentType.DIGID) {
        url = `/api/iop/digid/${id ?? form.value.uuid}`
      }
      const result = await backend.get(url)
      if (result.data.type !== formType) {
        await goToList()
      }
      form.value = result.data
    } catch (e) {
      await router.push('/home')
    }
  }

  const setForm = (formValue) => {
    form.value = formValue
  }

  const addForm = async () => {
    let model = null
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION:
        model = JSON.parse(JSON.stringify(WasteIdentificationModel.empty))
        break
      case DocumentType.GRONDBANK:
        model = JSON.parse(JSON.stringify(GrondbankModel.empty))
        break
      case DocumentType.ANNEX_7:
        model = JSON.parse(JSON.stringify(Annex7Model.empty))
        break
      default:
        console.error('unknown form type')
        return false
    }

    const result = await backend.post('api/documents', model)
    if (result.status === 200) {
      setForm(result.data)
      await goToEdit(result.data.id)
    } else {
      notifier.error('toast.form_create_failed')
    }
  }

  const demo = computed(() => {
    return form.value?.demo
  })

  const valid = computed(function () {
    return form.value && useFormStore().modelCheck()
  })

  const validAllowPast = computed(function () {
    return form.value && useFormStore().modelCheck({allowPast: true})
  })

  const date = computed(() => {
    return form.value?.transportDate ? DateFormatter.formatDate(form.value.transportDate) : ''
  })

  const updateDate = computed(() => {
    return form.value?.lastUpdate ? DateFormatter.formatDateTime(form.value?.lastUpdate) : ''
  })

  const modelCheck = (options = {}) => {
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION:
        return WasteIdentificationModel.modelCheck(form.value, options)
      case DocumentType.GRONDBANK:
        return GrondbankModel.modelCheck(form.value, options)
      case DocumentType.ANNEX_7:
        return Annex7Model.modelCheck(form.value, options)
      default:
        console.error('unknown form type')
        return false
    }
  }

  const formCheck = (hideToast) => {
    let formElement = null
    let err         = null
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION: {
        formElement = $('#waste-identification-form')[0]
        err         = WasteIdentificationModel.formCheck(form.value, FormState.isDraft(form.value.state))
        if (err && err === 'toast.invalid_amount_zero') {
          $('.waste-amount').get(0).scrollIntoView({behavior: 'smooth'})
        }
        break
      }
      case DocumentType.GRONDBANK: {
        formElement = $('#grondbank-form')[0]
        err         = GrondbankModel.formCheck(form.value, FormState.isDraft(form.value.state))
        break
      }
      case DocumentType.ANNEX_7: {
        formElement = $('#annex-7-form')[0]
        err         = Annex7Model.formCheck(form.value, FormState.isDraft(form.value.state))
        if (err && err === 'toast.invalid_waste_identifications') {
          $('#a7-add-identification').get(0).scrollIntoView({behavior: 'smooth'})
        }
        break
      }
    }

    if (err) {
      formElement && formElement.reportValidity()
      notifier.error(err)
      return false
    }

    if (!hideToast) {
      notifier.success('toast.form_valid')
    }
    return true
  }

  const validate = (hideToast) => {
    if (!validateIDs()) {
      return false
    }
    if (FormState.isDraft(form.value.state)) {
      return true
    }
    return formCheck(hideToast)
  }

  const validateIDs = () => {
    const vatElements = $('.identification-to-validate')
    for (let i = 0; i < vatElements.length; i++) {
      const el = vatElements[i]
      if (!el?.checkValidity()) {
        // something in the form is not valid so don't allow signing
        el?.reportValidity()
        return false
      }
    }
    return true
  }

  const saving = ref(false)
  const save   = async () => {
    saving.value = true
    if (!validate(true)) {
      saving.value = false
      return false
    }

    const warning = await warningChecks()
    if (warning) {
      saving.value = false
      await warningStore.showWarning(warning, performSave)
      return
    }

    await performSave()
  }

  const performSave = async () => {
    saving.value = true
    if (!validate()) {
      saving.value = false
      return false
    }

    try {
      let url = `api/documents/${form.value.id}`
      if (formType === DocumentType.DIGID) {
        url = `api/iop/digid/${form.value.uuid}`
      }
      const result = await backend.put(url, form.value)
      if (result.status === 200) {
        await goToView()
      } else {
        notifier.error('toast.save_failed')
      }
    } finally {
      saving.value = false
    }
  }

  const warningChecks = async () => {
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION: {
        return WasteIdentificationModel.warningChecks(form.value)
      }
      case DocumentType.GRONDBANK: {
        return GrondbankModel.warningChecks(form.value)
      }
      case DocumentType.ANNEX_7: {
        return Annex7Model.warningChecks(form.value)
      }
    }
  }

  const goToView = async (id) => {
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION:
        return router.push(`/waste-identification/view/${id ?? form.value.id}`)
      case DocumentType.GRONDBANK:
        return router.push(`/grondbank/view/${id ?? form.value.id}`)
      case DocumentType.ANNEX_7:
        return router.push(`/annex-7/view/${id ?? form.value.id}`)
      case DocumentType.DIGID:
        return router.push(`/external/waste-identification/view/${id ?? form.value.uuid}`)
    }
  }

  const goToEdit = async (id) => {
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION:
        return router.push(`/waste-identification/edit/${id ?? form.value.id}`)
      case DocumentType.GRONDBANK:
        return router.push(`/grondbank/edit/${id ?? form.value.id}`)
      case DocumentType.ANNEX_7:
        return router.push(`/annex-7/edit/${id ?? form.value.id}`)
      case DocumentType.DIGID:
        return router.push(`/external/waste-identification/edit/${id ?? form.value.uuid}`)
    }
  }

  const goToList = async () => {
    switch (formType) {
      case DocumentType.WASTE_IDENTIFICATION:
        return router.push(`/waste-identification/list`)
      case DocumentType.GRONDBANK:
        return router.push(`/grondbank/list`)
      case DocumentType.ANNEX_7:
        return router.push(`/annex-7/list`)
    }
  }

  const stop = () => {
    viewStep.value = ViewStep.ARRIVING
  }

  const isFinal = computed(() => {
    return form.value.state === FormState.FINISHED
  })

  return {
    init,
    form,
    resetForm,
    loadForm,
    reloadForm,
    setForm,
    addForm,
    loading,
    demo,
    valid,
    validAllowPast,
    date,
    updateDate,
    modelCheck,
    formCheck,
    warningChecks,
    validate,
    save,
    saving,
    performSave,
    goToView,
    goToEdit,
    goToList,
    viewStep,
    stop,
    isFinal,
  }
})