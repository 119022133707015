<template>
  <Popper arrow class="light" @close="closePopper" :show="showPopper && suggestions.length > 0"
          v-click-outside="closePopper">
    <ui-view-card-field data-cy="template-description" :label="$t('waste_identification_template.form.description')"
                        v-model="itemRef.description" :required="true"/>
    <template #content>
      <div v-for="suggestion in suggestions" :key="suggestion.description" class="popper-suggestion">
        <div @click="useSuggestion(suggestion, index)">
          <span>{{
              suggestion.description + ' (' + $t('processing_techniques.' + suggestion.processingTechnique) + ')'
            }}</span>
        </div>
      </div>
    </template>
  </Popper>
  <div class="row">
    <div class="col-lg-6">
      <ui-view-card-field data-cy="template-eural-code" :label="$t('waste_identification_template.form.EURAL-code')"
                          v-model="itemRef.euralCode" :required="true">
        <v-select :options="euralCodes" v-model="itemRef.euralCode" :reduce="code => code.value"
                  :filter="EuralCodes.ignoreSpaceSearch">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field data-cy="template-dangerous" :label="$t('waste_identification_template.form.dangerous')"
                          v-model="itemRef.dangerous" :required="true">
        <div class="form-check d-flex justify-content-center">
          <input class="form-check-input" type="checkbox" disabled v-model="itemRef.dangerous">
        </div>
      </ui-view-card-field>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field :label="$t('waste_identification_template.form.removal_operation')" :required="true">
        <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="itemRef.removalOperation"
                  :reduce="operation => operation.value">
          <template #selected-option="option">
            {{ option.value }}
          </template>
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field :label="$t('waste_identification_template.form.processing_technique')" :required="true">
        <v-select :key="processingTechniquesLoaded" :options="processingTechniques"
                  v-model="itemRef.processingTechnique" :reduce="operation => operation.value">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
    </div>
    <template v-if="itemRef.dangerous">
      <div class="col-lg-6">
        <ui-view-card-field data-cy="template-physical" :label="$t('waste_identification_template.form.physical')"
                            v-model="itemRef.physical" :required="true"/>
      </div>
      <div class="col-lg-6">
        <ui-view-card-field data-cy="template-chemical" :label="$t('waste_identification_template.form.chemical')"
                            v-model="itemRef.chemical" :required="true"/>
      </div>
      <div class="col-lg-6">
        <ui-view-card-field data-cy="template-packaging-type"
                            :label="$t('waste_identification_template.form.packaging_type')"
                            v-model="itemRef.packagingType" :required="true"/>
      </div>
      <div class="col-lg-6">
        <ui-view-card-number data-cy="template-amount-packages"
                             :label="$t('waste_identification_template.form.amount_of_packages')"
                             v-model="itemRef.amountOfPackages" :required="true"/>
      </div>
    </template>
    <ui-view-card-field v-if="includeAmount" :label="$t('waste_identification_template.form.amount')" :required="true">
      <div class="container">
        <div class="row">
          <number-input
            v-model="itemRef.amount" :required="true" min="0"
            :class="{'color-firebrick': Number(itemRef.amount) <= 0}"
          />
          <input class="col form-control" readonly :placeholder="$t('form.table.tonne')"
                 style="background-color: #e9ecef;"/>
        </div>
      </div>
    </ui-view-card-field>
  </div>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import {ref, watch} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'
import clearField from '@/services/clearfield'
import RemovalOperations from '@/models/RemovalOperations'
import ProcessingTechniques from '@/models/ProcessingTechniques'
import EuralCodes from '@/models/EuralCodes'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import NumberInput from '@/components/NumberInput.vue'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name:       'ui-view-card-waste',
  computed:   {
    EuralCodes() {
      return EuralCodes
    },
  },
  components: {
    UiViewCardNumber,
    NumberInput,
    UiViewCardField,
  },
  props:      {
    modelValue:    {
      default: {},
    },
    includeAmount: {
      type:    Boolean,
      default: false,
    },
  },
  setup:      (props, {emit}) => {
    const itemRef = useModelWrapper(props, emit)

    const suggestions = ref([])
    const hidePopup   = ref(true)
    const showPopper  = ref(true)
    watch(() => itemRef.value.description, debounce(async (newVal) => {
      if (newVal && !hidePopup.value) {
        backend.get(`api/wasteidentificationtemplates?search=${newVal}`).then((result) => {
          showPopper.value  = true
          suggestions.value = result.data
        })
      }
      hidePopup.value = false
    }, 400))

    setTimeout(() => {
      hidePopup.value = false // hide popup on open, then enable after half a second
    }, 500)

    const closePopper = () => {
      showPopper.value = false
    }

    const useSuggestion = (suggestion) => {
      let cleared = clearField(suggestion, 'id')
      cleared     = clearField(cleared, 'amount')
      Object.assign(itemRef.value, cleared)
      hidePopup.value = true
      closePopper()
    }

    const removalOperations       = ref([])
    const removalOperationsLoaded = ref(false)
    const getRemovalOperations    = async () => {
      removalOperations.value       = await RemovalOperations.get()
      removalOperationsLoaded.value = true
    }
    getRemovalOperations()

    const processingTechniques       = ref([])
    const processingTechniquesLoaded = ref(false)
    const getProcessingTechniques    = async () => {
      processingTechniques.value       = await ProcessingTechniques.get()
      processingTechniquesLoaded.value = true
    }
    getProcessingTechniques()

    const euralCodes       = ref([])
    const euralCodesLoaded = ref(false)
    const getEuralCodes    = async () => {
      euralCodes.value       = await EuralCodes.get()
      euralCodesLoaded.value = true
      checkDangerous()
    }
    getEuralCodes()

    const checkDangerous = () => {
      const eural             = getEural(itemRef.value.euralCode)
      itemRef.value.dangerous = eural?.dangerous ?? false
    }
    watch(() => itemRef.value.euralCode, () => {
      checkDangerous()
    })
    const getEural = (ec) => {
      for (let i = 0; i < euralCodes.value.length; i++) {
        if (euralCodes.value[i].value === ec) {
          return euralCodes.value[i]
        }
      }
      return null
    }

    return {
      itemRef,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
      removalOperations,
      removalOperationsLoaded,
      processingTechniques,
      processingTechniquesLoaded,
      euralCodes,
      euralCodesLoaded,
    }
  },
}
</script>

<style>
.color-firebrick {
  border-color: firebrick;
  color: firebrick;
}
</style>
