const DateValidator = {
  dateIsInPast: (date) => {
    const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours
    let transportDate = new Date(date)
    transportDate.setHours(transportDate.getHours() + localOffset)
    transportDate = transportDate.setUTCHours(0, 0, 0, 0)
    const today = new Date().setUTCHours(0, 0, 0, 0)
    return transportDate < today
  },
  dateIsOnOrAfter: (date, dateToCompare) => {
    const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours

    let transportDate = new Date(date)
    transportDate.setHours(transportDate.getHours() + localOffset)
    transportDate = transportDate.setUTCHours(0, 0, 0, 0)

    let dateToCompareLocal = new Date(dateToCompare)
    dateToCompareLocal.setHours(dateToCompareLocal.getHours() + localOffset)
    dateToCompareLocal = dateToCompareLocal.setUTCHours(0, 0, 0, 0)

    return transportDate >= dateToCompareLocal
  }
}


export default DateValidator