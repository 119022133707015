<template>
  <layout>
    <template #header>
      <ui-header :title="$t('digid.view.title', {id: form.id})" back-route="/external/waste-identification/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="digid-actions" class="dropdown-menu">
              <li>
                <button class="dropdown-item" @click="formStore.goToEdit()">
                  {{ $t('form.view.edit') }}
                </button>
              </li>
              <li>
                <button class="dropdown-item" :disabled="['IN TRANSIT', 'SIGNED'].indexOf(form.status) === -1"
                        @click="cancelStore.showCancel()" :title="$t('forms.view.only_signed_cancel')">
                  {{ $t('form.view.cancel') }}
                </button>
              </li>
            </ul>
          </div>
          <div class="btn-group">
            <button class="btn btn-outline-primary" v-if="form.status === 'SIGNED'" :disabled="locating"
                    @click="start()">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('form.view.start') }}
            </button>
            <button class="btn btn-outline-primary" v-else-if="form.status === 'IN TRANSIT'" :disabled="locating"
                    @click="arrive()">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('form.view.arrive') }}
            </button>
            <button class="btn btn-outline-primary" v-else-if="form.status === 'ARRIVED' && !form.signatureReceiver.name" :disabled="locating"
                    @click="signForDelivery()">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <external-digid-form-view v-if="!loading" :form="form"/>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout.vue'
import UiHeader from '@/components/ui-header.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiContent from '@/components/ui-content.vue'
import BREADCRUMBS from '@/util/breadcrumbs'
import {useFormStore} from '@/stores/FormStore'
import DocumentType from '@/types/documentType'
import {ref, watch} from 'vue'
import {storeToRefs} from 'pinia'
import ExternalDigidFormView from '@/partials/external/digid/FormView.vue'
import {useCancelStore} from '@/stores/modals/CancelStore'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'

export default {
  name:       'ExternalWasteIdentificationView',
  components: {
    ExternalDigidFormView,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const notifier    = Notifier()
    const breadcrumbs = [BREADCRUMBS.EXTERNAL, BREADCRUMBS.EXTERNAL_WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]

    const formStore = useFormStore()
    formStore.init(DocumentType.DIGID)

    formStore.loadForm(props.id)

    watch(() => props.id, () => {
      formStore.loadForm(props.id)
    })

    const cancelStore = useCancelStore()

    watch(() => formStore.form, (v) => {
      if (['IN TRANSIT', 'SIGNED'].indexOf(v.status) > -1) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = async () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const location    = geolocation.getLocation()
        const coordinates = location.split(',')
        backend.put(`api/iop/digid/${props.id}/start`, {
          'coordinates': coordinates,
        }).then(() => {
          notifier.success('toast.start_location_saved')
          formStore.reloadForm()
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const arrive = async () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          arrive()
        }, 3000)
      } else {
        const location    = geolocation.getLocation()
        const coordinates = location.split(',')
        backend.put(`api/iop/digid/${props.id}/arrive`, {
          'coordinates': coordinates,
        }).then(() => {
          notifier.success('toast.arrive_location_saved')
          formStore.reloadForm()
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const signForDelivery = async () => {
      const user     = await session.getUserRefetch()
      locating.value = true
      backend.put(`api/iop/digid/${props.id}/signReceiver`, {
        'name': (user.firstName ?? '') + ' ' + (user.lastName ?? ''),
      }).then(() => {
        notifier.success('toast.signed_for_delivery')
        formStore.reloadForm()
      }).catch(() => {
        notifier.error('toast.signing_for_delivery_failed')
      }).finally(() => {
        locating.value = false
      })
    }

    return {
      breadcrumbs,
      formStore,
      cancelStore,
      start,
      arrive,
      locating,
      signForDelivery,
      ...storeToRefs(formStore),
    }
  },
}
</script>
