import {defineStore} from "pinia";
import {ref} from "vue";
import backend from "@/util/backend";
import {useFormStore} from "@/stores/FormStore";
import Notifier from "@/util/notifier";

export const useCancelStore = defineStore('cancel', () => {
  const notifier = Notifier()
  const formStore = useFormStore()

  const cancelModal = ref('cancelModal')

  const showCancel = () => {
    cancelModal.value.modal.open()
  }

  const cancel = async (reason) => {
    const id = formStore.form?.id
    if (!id) {
      return notifier.error('toast.cancel_failed')
    }
    let url = `api/documents/${id}/cancel`
    if (formStore.form.type === DocumentType.DIGID) {
      url = `api/iop/digid/${formStore.form.uuid}/cancel`
    }

    try {
      const result = await backend.put(url, {
        'reason': reason,
      })
      if (result.status !== 200) {
        notifier.error('toast.cancel_failed')
      } else {
        notifier.success('toast.cancel_successful')
        formStore.setForm(result.data)
      }
    } catch (err) {
      notifier.error('toast.cancel_failed')
    } finally {
      cancelModal.value.modal.close()
    }
  }

  return {
    cancelModal,
    showCancel,
    cancel
  }
})