import i18n, {languages} from '../util/i18n'
import session from '../util/session'

const $t = () => {
    const user = session.getUser()
    return i18n(user?.language ?? languages.NL)
}

const IdentificationType = {
    VAT: "VAT",
    COMPANY: "COMPANY",
    BRANCH_NUMBER: "BRANCH_NUMBER",
    INDIVIDUAL: "INDIVIDUAL",
    PERMIT_NUMBER: "PERMIT_NUMBER",
    MMSI: "MMSI",
    IMO: "IMO",
  BRUDALEX: "BRUDALEX",
  SPW: "SPW",
    OVAM: "OVAM",

    getAll: (includeIndividual) => {
        let result = [
            {label: $t()('identification_types.VAT'), value: IdentificationType.VAT},
            {label: $t()('identification_types.COMPANY'), value: IdentificationType.COMPANY},
            {label: $t()('identification_types.BRANCH_NUMBER'), value: IdentificationType.BRANCH_NUMBER},
            {label: $t()('identification_types.INDIVIDUAL'), value: IdentificationType.INDIVIDUAL},
            // {label: $t()('identification_types.PERMIT_NUMBER'), value: IdentificationType.PERMIT_NUMBER},
            {label: $t()('identification_types.MMSI'), value: IdentificationType.MMSI},
            {label: $t()('identification_types.IMO'), value: IdentificationType.IMO},
          {label: $t()('identification_types.BRUDALEX'), value: IdentificationType.BRUDALEX},
          {label: $t()('identification_types.SPW'), value: IdentificationType.SPW},
            {label: $t()('identification_types.OVAM'),          value: IdentificationType.OVAM},
    ]
        if (!includeIndividual) {
            result = result.filter((s) => { return s.value !== IdentificationType.INDIVIDUAL})
        }
        return result
    }
}

export default IdentificationType
